import { useState, useEffect, useRef } from "react"
import useRaf from "@rooks/use-raf"

import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import {
  EffectComposer,
  ShaderPass,
  //   BokehPass,
  RenderPass,
} from "../three/postProcessing/postProcessing"
import { FXAAShader } from "../three/shaders/shaders"

// import global hooks
import useRenderer from "~hooks/useRenderer"
import usePlayheadPosition from "~hooks/usePlayheadPosition"
import { useWindowSize } from "react-use"

// import higher order components
import withComposition from "~higherOrderComponents/withComposition"

const Particles = ({ startTime, endTime, editorMode = false }) => {
  // State
  const [initialTime, setInitialTime] = useState()
  const [isReady, setIsReady] = useState(false)

  // Hooks
  const playheadPosition = usePlayheadPosition()
  const renderer = useRenderer()
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  // References
  const cameraReference = useRef()
  const sceneReference = useRef()

  const effectComposerReference = useRef()
  const knotReference1 = useRef(null)
  const knotReference2 = useRef(null)
  const knotReference3 = useRef(null)
  const controlsReference = useRef(null)

  // Constants
  const isActive =
    (!startTime && !endTime) ||
    (playheadPosition >= startTime && playheadPosition <= endTime)

  useEffect(() => {
    if (isReady && isActive && !initialTime) {
      setInitialTime(playheadPosition)
    }
  }, [isReady, isActive, playheadPosition, initialTime])

  useEffect(() => {
    if (cameraReference.current && effectComposerReference.current) {
      const camera = cameraReference.current
      camera.aspect = windowWidth / windowHeight
      camera.updateProjectionMatrix()
      effectComposerReference.current.setSize(windowWidth, windowHeight)
    }
  }, [windowWidth, windowHeight])

  useEffect(() => {
    const initialize = async () => {
      /* Constants and References */
      cameraReference.current = new THREE.PerspectiveCamera(
        75,
        windowWidth / windowHeight,
        0.1,
        1000
      )
      sceneReference.current = new THREE.Scene()
      effectComposerReference.current = new EffectComposer(renderer)
      const camera = cameraReference.current
      const scene = sceneReference.current
      const effectComposer = effectComposerReference.current

      /* 
        Configure Effect Composers 
      */

      camera.position.z = 4
      camera.position.y = 3
      // camera.position.x = 1.5

      /* 
        Configure Controls 
      */

      controlsReference.current = new OrbitControls(camera, renderer.domElement)
      controlsReference.current.autoRotate = true
      controlsReference.current.autoRotateSpeed = 1.0

      // SKYBOX skybox
      const cubeTextureLoader = new THREE.CubeTextureLoader()
      const skyTexture = cubeTextureLoader.load([
        "/nyio/cubemaps/inverted/px.jpg",
        "/nyio/cubemaps/inverted/nx.jpg",
        "/nyio/cubemaps/inverted/py.jpg",
        "/nyio/cubemaps/inverted/ny.jpg",
        "/nyio/cubemaps/inverted/pz.jpg",
        "/nyio/cubemaps/inverted/nz.jpg",
      ])
      scene.background = skyTexture

      function setGeom(g, color, x, y, z) {
        ToQuads(g)
        let m = new THREE.LineBasicMaterial({
          color: color,
        })
        let o = new THREE.LineSegments(g, m)
        o.position.set(x, y, z)
        o.rotation.x = Math.PI / 2
        scene.add(o)
      }

      function ToQuads(g) {
        let p = g.parameters
        let segmentsX =
          (g.type == "TorusBufferGeometry"
            ? p.tubularSegments
            : p.radialSegments) ||
          p.widthSegments ||
          p.thetaSegments ||
          p.points.length - 1 ||
          1
        let segmentsY =
          (g.type == "TorusBufferGeometry"
            ? p.radialSegments
            : p.tubularSegments) ||
          p.heightSegments ||
          p.phiSegments ||
          p.segments ||
          1
        let indices = []
        for (let i = 0; i < segmentsY + 1; i++) {
          let index11 = 0
          let index12 = 0
          for (let j = 0; j < segmentsX; j++) {
            index11 = (segmentsX + 1) * i + j
            index12 = index11 + 1
            let index21 = index11
            let index22 = index11 + (segmentsX + 1)
            indices.push(index11, index12)
            if (index22 < (segmentsX + 1) * (segmentsY + 1) - 1) {
              indices.push(index21, index22)
            }
          }
          if (
            index12 + segmentsX + 1 <=
            (segmentsX + 1) * (segmentsY + 1) - 1
          ) {
            indices.push(index12, index12 + segmentsX + 1)
          }
        }
        g.setIndex(indices)
      }

      /* Create Lights */

      let light = new THREE.DirectionalLight(0xdba342, 0.8)
      light.position.set(10, 10, 10)
      light.target.position.set(0, 0, 0)
      light.castShadow = true
      light.shadow.bias = -0.001
      light.shadow.mapSize.width = 2048
      light.shadow.mapSize.height = 2048
      light.shadow.camera.near = 0.1
      light.shadow.camera.far = 500.0
      light.shadow.camera.left = 10
      light.shadow.camera.right = -10
      light.shadow.camera.top = 10
      light.shadow.camera.bottom = -10
      light.intensity = 0.21 * 4
      scene.add(light)

      let light2 = new THREE.DirectionalLight(0xdba342)
      light2.position.set(-10, 10, -10)
      light2.target.position.set(0, 0, 0)
      light2.castShadow = true
      light2.shadow.bias = -0.001
      light2.shadow.mapSize.width = 2048
      light2.shadow.mapSize.height = 2048
      light2.shadow.camera.near = 0.1
      light2.shadow.camera.far = 500.0
      light2.shadow.camera.left = 10
      light2.shadow.camera.right = -10
      light2.shadow.camera.top = 10
      light2.shadow.camera.bottom = -10
      light2.intensity = 0.21
      scene.add(light2)

      const ambient_light = new THREE.AmbientLight(0x808080)
      scene.add(ambient_light)

      /* Configure Render Passes */
      effectComposer.addPass(new RenderPass(scene, camera))

      /* Configure FxaaPass */
      const fxaaPass = new ShaderPass(FXAAShader)
      const pixelRatio = renderer.getPixelRatio()
      const uniforms = fxaaPass.material.uniforms
      uniforms["resolution"].value.x = 1 / (window.innerWidth * pixelRatio)
      uniforms["resolution"].value.y = 1 / (window.innerHeight * pixelRatio)

      function generateSprite() {
        var canvas = document.createElement("canvas")
        canvas.width = 16
        canvas.height = 16

        var context = canvas.getContext("2d")
        var gradient = context.createRadialGradient(
          canvas.width / 2,
          canvas.height / 2,
          0,
          canvas.width / 2,
          canvas.height / 2,
          canvas.width / 2
        )
        gradient.addColorStop(0, "rgba(255,255,255,1)")
        gradient.addColorStop(0.2, "rgba(0,255,255,1)")
        gradient.addColorStop(0.4, "rgba(0,0,64,1)")
        gradient.addColorStop(1, "rgba(0,0,0,1)")

        context.fillStyle = gradient
        context.fillRect(0, 0, canvas.width, canvas.height)

        var texture = new THREE.Texture(canvas)
        texture.needsUpdate = true
        return texture
      }

      function createPoints(geom) {
        var material = new THREE.PointsMaterial({
          color: 0xffffff,
          size: 0.2,
          transparent: true,
          blending: THREE.AdditiveBlending,
          map: generateSprite(),
          depthWrite: false, // instead of sortParticles
        })

        var cloud = new THREE.Points(geom, material)
        return cloud
      }

      var geom = new THREE.TorusKnotGeometry(15, 39.7, 33, 4)

      knotReference1.current = createPoints(geom)
      knotReference1.current.scale.x = 0.5
      knotReference1.current.scale.y = 0.5
      knotReference1.current.scale.z = 0.5
      knotReference1.current.position.y = -10

      knotReference2.current = createPoints(geom)
      knotReference2.current.position.x = -20
      knotReference2.current.position.y = 20
      knotReference2.current.scale.x = 0.5
      knotReference2.current.scale.y = 0.5
      knotReference2.current.scale.z = 0.5

      knotReference3.current = createPoints(geom)
      knotReference3.current.position.x = 20
      knotReference3.current.scale.x = 0.5
      knotReference3.current.scale.y = 0.5
      knotReference3.current.scale.z = 0.5

      // add it to the scene.
      scene.add(knotReference1.current)
      scene.add(knotReference2.current)
      scene.add(knotReference3.current)

      /* Done Configuring Scene */
      setIsReady(true)
    }

    initialize()
  }, [])

  const update = () => {
    const controls = controlsReference.current
    controls.update()

    knotReference1.current.rotation.y -= 0.001
    knotReference1.current.position.y += 0.001
    knotReference2.current.rotation.y -= 0.0005
    knotReference2.current.position.y -= 0.001
    knotReference3.current.rotation.y += 0.001
    knotReference3.current.position.y += 0.0007
  }

  // Request Animation Frame
  useRaf(() => {
    update()
    if (effectComposerReference.current) {
      effectComposerReference.current.render()
    }
  }, isReady && isActive)

  return null
}

const ParticlesWithComposition = withComposition(Particles)

export default ParticlesWithComposition
